.tabs-area {
    .makeStyles-container-29 {
        z-index: 0;
    }
}

.jss2 {
    overflow: auto !important;
}

.OverlayContainer-container-2 {
    overflow: auto !important;
}


// Removed Details text, ugly implementation. Needs to be fixed.
.memo-titleLabel-383:first-of-type {
    display: none;
}

// Removed all appointments option, ugly implementation. Needs to be fixed.
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.Overlay-paper-10.MuiPaper-elevation24.MuiPaper-rounded {
    .MuiFormGroup-root {
        .MuiFormControlLabel-root:nth-of-type(3) {
            display: none;
        }
    }
}